import * as React from 'react'
import styled from 'styled-components'
import DefaultLayout from '../../layouts'
import { ButtonSpaced } from '../Button'
import { navigate } from 'gatsby'

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 20px 10%;
`

const HeadlineText = styled.h1`
  font-weight: 600;
  font-size: 42px;
  line-height: 1.3;
  text-align: center;
  color: var(--black);
  margin-bottom: 50px;
`

const QuestionText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  color: var(--black);
  margin-bottom: 15px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: 500px) {
    width: 500px;
    flex-direction: row;
  }
`

const SurveyPage = () => {
  return (
    <DefaultLayout language="en">
      <PageWrapper>
        <HeadlineText>Welcome to the State of Freelancing in Israel 2020</HeadlineText>
        <QuestionText>Which survey would you like to fill out?</QuestionText>
        <ButtonsWrapper>
          <ButtonSpaced
            onClick={() => {
              navigate('/freelancer-survey')
            }}
          >
            Freelancer
          </ButtonSpaced>
          <ButtonSpaced
            onClick={() => {
              navigate('/company-survey')
            }}
          >
            Client
          </ButtonSpaced>
        </ButtonsWrapper>
      </PageWrapper>
    </DefaultLayout>
  )
}

export default SurveyPage
