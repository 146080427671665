import { graphql } from 'gatsby'
import * as React from 'react'

import SurveyPage from '../components/pages/SurveyPage'
import DefaultLayout from '../layouts'

const Survey = () => (
  <DefaultLayout
    language="en"
    title="The State of Freelancing in Israel 2020"
    description="Freelancers and industry leaders give their thoughts on the current state of freelancing in Israel"
  >
    <SurveyPage />
  </DefaultLayout>
)

export default Survey

export const pageQuery = graphql`
  query SurveyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
